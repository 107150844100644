<template>
  <div>
    <el-row :gutter="0">
      <el-col :span="20" :offset="2">
    <header class="page-header">
        <el-row :gutter="20" align="middle">
        <el-col :span="12">
          <h2>Харилцагчийн жагсаалт</h2>
        </el-col>
        <el-col :span="12">
            <div class="text-right">
            <router-link to="/client-contact-add">
                <el-button type="success" class="item">Нэмэх</el-button>
            </router-link>
            </div>
        </el-col>
        </el-row>
    </header>
    <div class="panel tabled">
      <el-tabs v-model="activeTabStatus">
      <el-tab-pane label="Бүгд" name="all">
        <div class="panel-content">
        <div class="pl20 pr20 table-filter mb10">
          <el-row :gutter="10" align="middle" type="flex">
            <el-col :span="12" class="ml20">
              <el-input placeholder="Хайх" class="input-with-select" @input="onSearch" v-model="search">
                <el-button
                @click="onSearch"
                  slot="prepend"
                  icon="el-icon-search"
                ></el-button>
              </el-input>
            </el-col>
          </el-row>
        </div>
    <el-table
      @cell-click="cellClick"
      v-loading="isLoading"
      :data="clientData"
      style="width: 100%">
      <el-table-column
        prop="name"
        label="Нэр">
      </el-table-column>
      <el-table-column
        prop="major"
        label="Албан тушаал">
      </el-table-column>
      <el-table-column
        prop="phone"
        label="Утас">
      </el-table-column>
      <el-table-column
        prop="email"
        label="Е-мэйл">
      </el-table-column>
      <el-table-column
        prop="address"
        label="Хаяг">
      </el-table-column>
    </el-table>
    <el-pagination
    @current-change = "curentPageHandler"
    @size-change = "sizeChangeHandler"
    class="text-right mt10"
    background
    :current-page.sync="currentPage"
    :page-size="pageSize"
    :page-sizes="[5, 20, 50, 100]"
    layout="total, sizes, prev, pager, next"
    :total="totalCount">
    </el-pagination>
    </div>
    </el-tab-pane>
    </el-tabs>
    </div>
    </el-col>
    </el-row>
  </div>
</template>
<script>
import services from '../../../helpers/services'
import { getSupplierId } from '../../../utils/auth'
export default {
  components: {
  },
  data () {
    return {
      clientData: [],
      isLoading: false,
      search: this.$route.query.search ? this.$route.query.search : '',
      activeTabStatus: 'all',
      pageSize: this.$route.query.size ? JSON.parse(this.$route.query.size) : 20,
      currentPage: this.$route.query.page ? JSON.parse(this.$route.query.page) : 1,
      totalCount: 0
    }
  },
  mounted () {
    var from = (this.pageSize * (this.currentPage - 1))
    var size = this.pageSize
    if (this.$route.query.page && this.$route.query.size) {
      const incomingPage = parseFloat(this.$route.query.page)
      const incomingSize = parseFloat(this.$route.query.size)
      this.currentPage = incomingPage
      this.pageSize = incomingSize
      from = (this.pageSize * (incomingPage - 1))
      size = incomingSize
    }
    this.pageFrom = from
    this.pageSize = size
    this.getEmployees(size, from)
  },
  methods: {
    cellClick (row, column, cell, event) {
      this.$router.push(
        {
          name: 'clientContactEdit',
          params: {
            employee_id: row.employee_id
          }
        }
      )
    },
    sizeChangeHandler (size) {
      this.pageSize = size
      this.$router.push({ name: 'clientContactList', query: { page: this.currentPage, size: size } }).catch(() => {})
      this.getEmployees(this.pageSize, (this.pageSize * (this.currentPage - 1)), this.search)
    },

    curentPageHandler (current) {
      this.currentPage = current
      this.$router.push({ name: 'clientContactList', query: { page: current, size: this.pageSize } }).catch(() => {})
      this.getEmployees(this.pageSize, (this.pageSize * (this.currentPage - 1)), this.search)
    },
    onSearch () {
      this.$router.push({ name: 'clientContactList', query: { page: this.currentPage, size: this.pageSize, search: this.search } }).catch(() => {})
      this.getEmployees(this.pageSize, (this.pageSize * (this.currentPage - 1)), this.search)
    },
    async getEmployees (size, from) {
      const supplierIds = await getSupplierId()
      this.isLoading = true
      const query = '?search_text=' + this.search + '&size=' + size + '&from=' + from + '&supplier_id=' + supplierIds
      services.getEmployees(query).then(response => {
        if (response.status === 'success' && response.data) {
          this.clientData = response.data
          this.totalCount = response.total
          this.isLoading = false
        }
      })
    }
  }
}
</script>
